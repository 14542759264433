// breakpoint
$xs: 480px;
$sm: 640px;
$md: 1024px;
$lg: 1170px;

// main colors
$main-color: #1900ff;
$secondary-color: #8EF9F3;
$grey-light: #f3f3f3;
$alice-blue: #f2f7fa;
$purple: #8a4d76;
$pink: #fa7c91;
$white: white;
$input-focus:#0000ff45;

// secondary colors
$blue-light: #e5edfb;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$secondary-text-color: #484a3f;
$border-color: #dcdcdc;
$border-color-hover: #939292;

// Bulma's global variables
$family-sans-serif: "Cambay", sans-serif;
$grey-dark: $brown;
$primary: $purple;
$link: $pink;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Bulma's component variables
$body-color: $secondary-text-color;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

// gradients
$navigation-bar-gradient: linear-gradient(rgba(5,43,60,0),rgba(5,43,60,.05)),#fff;

// shadows
$box-shadow--blue: 2px 2px 4px rgba(25, 0, 255, 0.2);
$box-shadow--light: 2px 2px 4px rgba(171, 170, 175, 0.2);

// clip-path
$bottom-polygon: polygon(100% 0%, 0% 0%, 0% 100%, 100% 85%);
