@import '../variables';

.card {
	display: inline-block;
	
  background-color: white;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: $box-shadow--light;
	transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

  .card-image {
		margin-bottom: -10px;
		position: relative;
  }

  .meta {
    width: 100%;
		background: white;
    padding: 14px 18px;
    border-radius: 4px;
    border-top: 1px solid $border-color;

    .flex {
      display: flex;
      justify-content: space-between;
    }

    .meta-title {
      margin-bottom: 8px;
		}
		
		.meta-description {
			margin-bottom: 8px;
		}

    .badges-group {
      display: flex;
      flex-wrap: wrap;

      .badge {
        font-size: 14px;
        padding: 7px 8px 4px 8px;
        margin-right: 8px;
        line-height: 1;
				background: $navigation-bar-gradient;
        border-radius: 4px;
        box-shadow: $box-shadow--light;

        &.platform {
          border: 1px solid $main-color;
        }
        &.workScope {
          border: 1px solid $secondary-color;
        }
      }
    }
    .portfolio-title {
      margin-bottom: 8px;
    }
  }

	// card hover
  &:hover {
		// box-shadow: 5px 4px 4px rgba(171, 170, 175, 0.2);
		transform: scale(1.01) !important;
		
		box-shadow: 0.5rem 0.5rem 0 rgba(233,236,239,.5);
    // transform: translate3d(-4px,-4px,0) rotateZ(-1deg);

    .card-image {
			&:before {
				content: "";
				position: absolute;
				background: #9dcbe685;
				height: calc(100% - 8px);
				width: 100%;
				display: block;
			}	
    }

    .quick-links {
			visibility: visible;
			
			.quicklinks--link {
				transform: translateY(0);
			}
    }
  }
}

