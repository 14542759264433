@import '../../variables';

.portfolio-single--grid {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 1fr;
	padding-bottom: 3rem;
	
	@media only screen and (min-width: $md) {		
		grid-template-columns: 30% 1fr;
	}

	.action {
		padding: 24px 0;
	}

	.design-by {
		margin-top: 12px;
	}
}

.portfolio-sidebar--sticky {
	@media only screen and (min-width: $md) {
		position: -webkit-sticky;
		position: sticky;
		top: 12px;
		height: 100vh;
	}
}