@import './variables';

// unitless function to convert px to em
@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @if (unitless($context)) {
    $context: $context * 1px;
  }
  @return $pixels / $context * 1em;
}

// breakpoint mixin
@mixin breakpoint($mq01: 0, $mq2: false, $maxmin: max-width) {
  @if $mq2 == false {
    @media ($maxmin: $mq01) {
      @content;
    }
  } @else {
    @media (min-width: $mq01) and (max-width: $mq2) {
      @content;
    }
  }
}

// responsive font size mixin
@mixin font-size-map($font-size-map) {
  @each $breakpoint, $font-size in $font-size-map {
    @if $breakpoint == null {
      font-size: em($font-size);
    } @else {
      @include breakpoint($breakpoint) {
        font-size: em($font-size);
      }
    }
  }
}


// body font size
$browser-context: 16;

// typography scale
$body-font-size: (
  null: 18,
  $md: 16,
  $sm: 14
);
$h1-font-size: (
  null: 56,
  $md: 44,
  $sm: 40,
  $xs: 36
);
$h2-font-size: (
  null: 40,
  $md: 36,
  $sm: 32,
  $xs: 28
);
$h3-font-size: (
  null: 32,
  $md: 28,
  $sm: 24,
  $xs: 20
);
$h4-font-size: (
  null: 28,
  $md: 22,
  $sm: 20,
  $xs: 20
);

html {
  line-height: 1.6;

  // make the text more clear, crisp and defined on all browsers
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include font-size-map($body-font-size);
}

// fonts
h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
  color: #333;
  font-weight: bold;
}

h1 {
  @include font-size-map($h1-font-size);
}
h2 {
  @include font-size-map($h2-font-size);
}
h3 {
  @include font-size-map($h3-font-size);
}
h4 {
  @include font-size-map($h4-font-size);
}

// text shadows
h1, h2 {
	text-shadow: 1.5px 1.5px 0px #8ef8f3;
	color: $main-color;
}
