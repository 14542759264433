// BACKGROUNDS
.has-background-white {
	background-color: $white;
}
.has-background-blue-light {
	background: $alice-blue;
}

// PADDINGS
.p-0 {
	padding: 0 !important;
}

// ALIGNMENTS
.flex--align-center {
	align-items: center;
}

// MARGINS
.m-lr-30 {
	margin: 0 30px !important;
}

//screen reader
.screen-reader {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}