@import "../variables";

.quick-links {
  display: flex;
  position: absolute;
  visibility: hidden;
  top: 45%;
  left: 50%;
	transform: translate(-50%, -50%);
	background: #efefef7a;
	border-radius: 100vw;
	padding: 4px;

  .quicklinks--link {
    display: block;
    width: 55px;
    height: 55px;
    margin: 4px;
    border-radius: 100px;
    background-color: $main-color;
    transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    position: relative;
    transform: translateY(50%);

    &:first-child {
      transition-delay: 0ms;
    }

    &:last-child {
      transition-delay: 50ms;
    }

    // .quick--link hover
    &:hover {
      background-color: #e5edfb;

      .link-svg {
        fill: $main-color;
        stroke: $main-color;

        circle {
          stroke: #fff;
        }
      }
    }

    .link-svg {
      fill: #fff;
      width: 24px;
      height: 24px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(0);
    }
  }
}
