@charset "utf-8";

// Import only what you need from Bulma
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/elements/button.sass";
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/elements/form.sass";
@import "../node_modules/bulma/sass/elements/title.sass";
@import "../node_modules/bulma/sass/components/navbar.sass";
@import "../node_modules/bulma/sass/layout/hero.sass";
@import "../node_modules/bulma/sass/layout/section.sass";
@import "../node_modules/bulma/sass/grid/columns.sass";

// Import variables & typography
@import "./typography";
@import "./variables";
@import "./utility";

body {
	font-family: $family-sans-serif;
  min-height: 100vh;
	margin: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
	padding: 0 1rem;
}

@media screen and (min-width: $md) {
	.section.is-medium {
			margin: 9rem 0;
			padding: 0 1.5rem;
	}
}
